import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { ConfirmPopupComponent } from '../shared/popup/confirm-popup/confirm-popup.component';
import { HelpersService } from "../shared/services/helpers.service";
import { SharedProductDTO } from '../shared/models/shareditem/shared-product.dto';
import { SharingStatusDTO } from '../shared/models/shareditem/sharing-status.dto';
import { SharedItem } from '../shared/models/shareditem/shared-item';
import { SharedItemDTO } from '../shared/models/shareditem/shared-item.dto';
import { SharedProductNewComponent } from './shared-product-new/shared-product-new.component';

@Component({
  selector: 'app-shared-product',
  templateUrl: './shared-product.component.html',
  styleUrls: ['./shared-product.component.css']
})
export class SharedProductComponent {
  dataSource = new MatTableDataSource<SharedProductDTO>();
  dataLoaded = false;
  displayedColumns: string[] = ['OD', 'productName', 'productNumber', 'changedDate', 'changedByEmail', 'sharingStatus', 'options'];
  sharedItem: SharedItem;
  pageTitle: string;
  breadcrumb = [];

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private activatedRouter: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRouter.params.subscribe(params => {
      if (params['sharedItemId']) {
        this.getInformation(params['sharedItemId']);
      }
    })
  }

  getInformation(sharedItemId: string) {
    this.http.get<SharedItem>("/api/v1/shared-item/" + sharedItemId).subscribe(
      (res: SharedItem) => {
        this.setPageInformation(res);
        this.sharedItem = res;

        this.dataSource.data = res.productList.map((x: SharedProductDTO) => {
          return {
            productName: x.productName,
            productNumber: x.productNumber,
            sharingStatus: x.sharingStatus,
            creationDateTime: x.creationDateTime,
            createdByUserFullName: x.createdByUserFullName,
            createdByUserEmail: x.createdByUserEmail
          };
        });
        this.dataLoaded = true;
      }
    );
  }

  setPageInformation(sharedItem: SharedItem): void {
    const customer: string = ((sharedItem.customerName !== '') ? sharedItem.customerName : sharedItem.customerNumber)
    this.pageTitle = customer  + " - Shared Products";
    this.breadcrumb = [
      { title: 'Shared Items', href: '/shared-item' },
      { title: this.pageTitle, href: '' }
    ];
  }

  changeSharingStatus(sharedProduct: SharedProductDTO): void {
    let body: SharingStatusDTO = {
      sharedItemId: this.sharedItem.id,
      sharingStatus: !sharedProduct.sharingStatus,
      productNumber: sharedProduct.productNumber
    }

    this.dataLoaded = false;

    this.http.put<SharingStatusDTO>('/api/v1/shared-item/shared-product', body, this.HttpOptions).subscribe({
      next: () => {
        this.getInformation(this.sharedItem.id);
      },
      error: (error) => {
        if (error.status == 500 || error.status == 405) {
          this.activateSnackBar('red-snackbar', error.error.error);
        } else {
          this.activateSnackBar('red-snackbar', error.error);
        }
      }
    });
  }

  addProductsToSharedItem(): void {
    let body: SharedItemDTO = {
      odName: this.sharedItem.odName,
      customerName: this.sharedItem.customerName,
      customerNumber: this.sharedItem.customerNumber,
      productList: [],
      allProductsSelected: this.sharedItem.allProductsSelected,
    }

    this.dataLoaded = false;

    this.http.post<SharingStatusDTO>('/api/v1/shared-item/shared-product', body, this.HttpOptions).subscribe({
      next: () => {
        this.getInformation(this.sharedItem.id);
      },
      error: (error) => {
        if (error.status === 500 || error.status === 405) {
          this.activateSnackBar('red-snackbar', error.error.error);
        } else {
          this.activateSnackBar('red-snackbar', error.error);
        }
      }
    });
  }

  openConfirmationPopup(sharedProduct: SharedProductDTO): void {
    const productNameShown = (sharedProduct.productName !== '') ? sharedProduct.productName : sharedProduct.productNumber;
    let dialogTitle: string, dialogContent: string, buttonText: string;

    if (sharedProduct.sharingStatus) {
      dialogTitle = `Are you sure you want to deactivate the sharing status for '${productNameShown}' product?`;
      buttonText = "Deactivate sharing"

      // Check if the sharingStatus of sharedItem will also be deactivated
      const trueStatusCount = this.sharedItem.productList.filter(product => product.sharingStatus).length;

      if (trueStatusCount === 1) {
        dialogContent = this.deactivateDialogContent();
      }
    } else {
      dialogTitle = `Are you sure you want to activate the sharing status for '${productNameShown}' product?`;
      buttonText = "Activate sharing"

      // Check if the sharingStatus of sharedItem will also be activated
      const allProductsFalse = this.sharedItem.productList.every(product => !product.sharingStatus);

      if (allProductsFalse) {
        dialogContent = this.activateDialogContent();
      }
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: dialogTitle,
        dialogContent: dialogContent,
        buttonText: buttonText
      },
      position: { top: '150px' },
      maxWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.changeSharingStatus(sharedProduct);
    });
  }

  activateDialogContent(): string {
    return `You are about to activate the sharing status of the first product for this customer. Please be aware that this action will have the following consequences:
        <ol>
          <li>This product's sharing status will be set to active.</li>
          <br>
          <li>As this is the first product to be activated, the overall sharing status for the customer will also be activated.</li>
        </ol>
        This means that after this action, this specific product will be shared, and the customer's overall sharing status will be set to active.`;
  }

  deactivateDialogContent(): string {
    return `You are about to deactivate the sharing status of the last active product for this customer. Please be aware that this action will have the following consequences:
        <ol>
          <li>This product's sharing status will be set to inactive.</li>
          <br>
          <li>As this is the last active product, the overall sharing status for the customer will also be deactivated.</li>
        </ol>
        This means that after this action, no products will be shared for this customer, and the customer's overall sharing status will be set to inactive.`;
  }

  isPermittedAction(action: string) {
    return this.authenticationService.isPermitedActionForUserGroups(action);
  }

  openAddProductsPopupForm(): void {
    const dialogRef = this.dialog.open(SharedProductNewComponent, {
      position: { top: '200px' },
      minWidth: '600px',
      data: {
        sharedItem: this.sharedItem
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.getInformation(this.sharedItem.id);
    });
  }

  activateSnackBar(type: string, message: string): void {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  protected readonly HelpersService = HelpersService;
}

