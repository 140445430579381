import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HelpersService } from '../shared/services/helpers.service';

import { ConfirmPopupComponent } from '../shared/popup/confirm-popup/confirm-popup.component';
import { SharingStatusDTO } from '../shared/models/shareditem/sharing-status.dto';
import { SharedItem } from '../shared/models/shareditem/shared-item';
import { SharedProductDTO } from '../shared/models/shareditem/shared-product.dto';
import { Group } from '../core/models/group';
import { ChangeConfigurationDTO } from '../shared/models/shareditem/change-configuration.dto';
import { SharedProductNewComponent } from '../shared-product/shared-product-new/shared-product-new.component';

@Component({
  selector: 'app-shared-item',
  templateUrl: './shared-item.component.html',
  styleUrls: ['./shared-item.component.css']
})
export class SharedItemComponent {
  dataSource = new MatTableDataSource<SharedItem>();
  dataLoaded = false;
  displayedColumns: string[] = ['OD', 'customerName', 'customerNumber', 'productList', 'sharingStatus', 'options'];

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getInformation();
  }

  getInformation(): void {
    const adminUser: boolean = this.authenticationService.isUserInAtLeastInOneOfGroups([Group.ADMIN, Group.DEVELOPER]);
    let apiRoute: string;

    if (adminUser) {
      apiRoute = "/api/v1/shared-item"
    } else {
      const userOdOwner = this.authenticationService.getOdFromOrgCode();

      apiRoute = `/api/v1/shared-item/od-name/${userOdOwner}`;
    }

    this.http.get<any>(apiRoute).pipe(map(res => res.map((x: SharedItem) => ({
      id: x.id,
      odName: x.odName,
      customerName: x.customerName,
      customerNumber: x.customerNumber,
      sharingStatus: x.sharingStatus,
      productList: this.getProductListString(x.productList),
      allProductsSelected: x.allProductsSelected,
      creationDateTime: (x.creationDateTime !== undefined) ? new Date(x.creationDateTime).toLocaleString() : '',
      modificationDateTime: (x.modificationDateTime !== undefined) ? new Date(x.modificationDateTime).toLocaleString() : '',
      createdByUserFullName: x.createdByUserFullName,
      createdByUserEmail: x.createdByUserEmail
    })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );
  }

  getProductListString(productList: SharedProductDTO[]) {
    if (productList === undefined) {
      return '';
    }

    const propertyList = productList.map(product => {
      const value = product['productName'];
      if (typeof value === 'string') {
        return value.trim() === '' ? HelpersService.getTrimmedMaterialNumber(product['productNumber']) : value;
      }
      if (value === null || value === undefined) {
        return HelpersService.getTrimmedMaterialNumber(product['productNumber']);
      }
      return (value as any).toString();
    });
    return propertyList.join(', ');
  }

  changeSharingStatus(sharedItem: SharedItem) {
    let body: SharingStatusDTO = {
      sharedItemId: sharedItem.id,
      productNumber: "",
      sharingStatus: !sharedItem.sharingStatus
    }

    this.dataLoaded = false;

    this.http.put<SharedItem>('/api/v1/shared-item', body, this.HttpOptions).subscribe({
      next: () => {
        this.getInformation();
        this.activateAlert('green-snackbar', 'Sharing status has been changed successfully!');
      },
      error: (error) => {
        if (error.status == 500 || error.status == 405) {
          this.activateAlert('red-snackbar', error.error.error);
        } else {
          this.activateAlert('red-snackbar', error.error);
        }
        this.dataLoaded = true;
      }
    });
  }

  openConfirmationPopup(sharedItem: SharedItem) {
    const customerNameShown = (sharedItem.customerName !== '') ? sharedItem.customerName : HelpersService.getTrimmedMaterialNumber(sharedItem.customerNumber);
    let dialogTitle: string, dialogContent: string, buttonText: string;

    if (sharedItem.sharingStatus) {
      dialogTitle = `Are you sure you want to deactivate the sharing status for '${customerNameShown}' customer ?`;
      dialogContent = "The sharing status of the customer and all it's associated products will be deactivated."
      buttonText = "Deactivate sharing"
    } else {
      dialogTitle = `Are you sure you want to activate the sharing status for '${customerNameShown}' customer ?`;
      dialogContent = "The sharing status of the customer and all it's associated products will be activated."
      buttonText = "Activate sharing"
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: dialogTitle,
        dialogContent: dialogContent,
        buttonText: buttonText
      },
      position: { top: '200px' },
      maxWidth: '40vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.changeSharingStatus(sharedItem);
    });
  }

  changeConfiguration(sharedItem: SharedItem) {
    let body: ChangeConfigurationDTO = {
      sharedItemId: sharedItem.id,
      allProductsSelected: !sharedItem.allProductsSelected
    }

    this.dataLoaded = false;

    this.http.put<SharedItem>('/api/v1/shared-item/change-configuration', body, this.HttpOptions).subscribe({
      next: () => {
        this.getInformation();
        this.activateAlert('green-snackbar', 'Shared Item configuration has been changed successfully!');
      },
      error: (error) => {
        if (error.status == 500 || error.status == 405) {
          this.activateAlert('red-snackbar', error.error.error);
        } else {
          this.activateAlert('red-snackbar', error.error);
        }
        this.dataLoaded = true;
      }
    });
  }

  openAddProductsPopupForm(sharedItem: SharedItem): void {
    const dialogRef = this.dialog.open(SharedProductNewComponent, {
      position: { top: '200px' },
      maxWidth: '60vw',
      data: {
        sharedItem,
        showChangeConfigurationMessage: true
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result != undefined) {
        this.changeConfiguration(sharedItem);
      }
    });
  }

  openChangeConfigurationConfirmationPopup(sharedItem: SharedItem) {
    const customerNameShown = (sharedItem.customerName !== '') ? sharedItem.customerName : HelpersService.getTrimmedMaterialNumber(sharedItem.customerNumber);
    let dialogTitle: string, dialogContent: string, buttonText: string;

    if (sharedItem.allProductsSelected) {
      dialogTitle = `Are you sure you want to change the configuration for '${customerNameShown}' customer to 'Selected products'?`;
      dialogContent = `
        <div>You are about to change the configuration for the selected Shared Item from 'All products' to 'Selected products'. 
        Please be aware that this action will have the following consequences:</div>
        <ol>
          <li>All Digital Twins based on the old configuration will not be removed. New Digital Twin will be created based on the new configuration.</li><br>
          <li>You will be redirected to 'All products' form to select specific products.</li><br>
          <li>If no products are added, the configuration will remain as 'All products'.</li>
        </ol>`;
    } else {
      dialogTitle = `Are you sure you want to change the configuration for '${customerNameShown}' customer to 'All products'?`;
      dialogContent = `
        <div>You are about to change the configuration for the selected Shared Item from 'Selected products' to 'All products'. 
        Please be aware that this action will have the following consequences:</div>
        <ol>
          <li>All Digital Twins based on the old configuration will not be removed. New Digital Twin will be created based on the new configuration.</li><br>
          <li>All associated products will be deleted.</li><br>
          <li>All products will be shared for this customer.</li>
        </ol>`;
    }

    buttonText = "Change configuration"

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: dialogTitle,
        dialogContent: dialogContent,
        buttonText: buttonText
      },
      position: { top: '150px' },
      maxWidth: '50vw',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (!sharedItem.allProductsSelected)
          this.changeConfiguration(sharedItem);
        else
          this.openAddProductsPopupForm(sharedItem);
      }
    });
  }

  isPermittedAction(action: string) {
    return this.authenticationService.isPermitedActionForUserGroups(action);
  }

  showComponentHistory(sharedItem: SharedItem) {
    this.dialog.open(ConfirmPopupComponent, {
      data: {
        dialogTitle: 'Shared Item History 121',
        dialogContent: `
          <p><strong>Creation Date:</strong> ${sharedItem.creationDateTime} </p>
          <p><strong>Modification Date:</strong> ${(sharedItem.modificationDateTime) ? sharedItem.modificationDateTime : '-' } </p>
          <p><strong>Created By:</strong> ${sharedItem.createdByUserEmail} </p>`,
        buttonText: 'Ok'
      },
      position: { top: '200px' },
      maxWidth: '50vw',
    });
  }

  activateAlert(type: string, message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  protected readonly HelpersService = HelpersService;
}
