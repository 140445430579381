import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {MatDialog} from '@angular/material/dialog';

import {ConfirmDeletePopupComponent} from '../../shared/popup/confirm-delete-popup/confirm-delete-popup.component';
import {ConfirmPopupComponent} from "../../shared/popup/confirm-popup/confirm-popup.component";
import {MessageService} from '../../shared/services/message.service';

import {MatSnackBar} from '@angular/material/snack-bar';
import {QualityNotification} from "../../shared/models/notifications/quality-notification";

@Component({
  selector: 'app-quality-notification',
  templateUrl: './quality-notification.component.html',
  styleUrls: ['./quality-notification.component.css']
})
export class QualityNotificationComponent {
  dataSource = new MatTableDataSource<QualityNotification>();
  dataLoaded = false;
  displayedColumns: string[] = ['notificationId', 'senderBpn', 'sentDateTime', 'status', 'severity', 'options'];
  dialogOpen = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public dialog: MatDialog,
    public messageService: MessageService) {
    this.getQualityNotifications();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getQualityNotifications() {
    this.http.get<any>("/api/v1/notifications/qualitynotifications").pipe(map(res => res.map((x: QualityNotification) => ({
      notificationId: x.content.notificationId,
      sentDateTime: x.header.sentDateTime,
      senderBpn: x.header.senderBpn,
      status: x.content.status,
      severity: x.content.severity
    })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.snackBar.open(message.text, '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      }
    });
  }

  deleteQualityNotification(qualityNotificationId: string) {
    this.http.delete(`/api/v1/notifications/qualitynotifications/${qualityNotificationId}`).subscribe(
      () => {
        this.snackBar.open("Quality notification deleted successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });

        this.getQualityNotifications()
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    )
  }

  openDeleteConfirmationPopup(notificationId: string) {
    const dialogRef = this.dialog.open(ConfirmDeletePopupComponent, {
      data: {elementToDelete: 'Quality Notification', id: notificationId},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.deleteQualityNotification(notificationId);
    });
  }

  openQualityNotificationPopup(notificationId: string) {
    this.dialogOpen = true;
    this.http.get(`/api/v1/notifications/qualitynotifications/${notificationId}`).subscribe(
      (response: any) => {
        let json: string = JSON.stringify(response, null, 2);

        this.dialog.open(ConfirmPopupComponent, {
          data: {
            dialogTitle: 'View Quality Notification',
            dialogContent: `<pre id="pre">${json}</pre>`,
            buttonText: 'Ok'
          },
          position: {top: '100px'},
          maxWidth: '90vw',
          width: '60vw'
        }).afterClosed().subscribe(result => {
          this.dialogOpen = false;
        });
      },
      error => {
        console.error('Error fetching quality notification:', error);
      }
    );
  }
}
