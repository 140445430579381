import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ConfirmPopupComponent } from '../shared/popup/confirm-popup/confirm-popup.component';
import { DigitalTwinAspectModelsDTO } from '../shared/models/digital-twin-test-aspect-models.dto';
import { Customer, DigitalTwin, DigitalTwinType } from '../shared/models/digital-twin.dto';
import { MessageService } from '../shared/services/message.service';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-digital-twin-test-new',
  templateUrl: './digital-twin-test-new.component.html',
  styleUrls: ['./digital-twin-test-new.component.css']
})
export class DigitalTwinTestNewComponent {
  form: FormGroup;
  DigitalTwinType = DigitalTwinType;
  breadcrumb: any;
  dataLoaded = false;
  digitalTwinPageTitle: string;

  digitalTwinAspectModelsDTO: DigitalTwinAspectModelsDTO = null

  savingFromScratch: boolean = true

  panelOpenState1 = false;
  panelOpenState3 = false;

  warningInputFieldsRequired: string = "There are required input fields that need to be filled out.";

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: undefined,
      cxIdentification: [{ value: '', disabled: true }],
      cxGlobalAssetId: [{ value: '', disabled: true }],
      production: false,
      isRegistered: false,
      bpn: [{ value: '', disabled: true }, [Validators.required]],
      materialNumberBASF: ['', [Validators.required]],
      batchNumber: ['', [Validators.required]],
      digitalTwinType: ['', [Validators.required]],
      customerList: this.formBuilder.array([])
    });

    this.getInformation();
  }


  getInformation() {
    this.route.params.subscribe(params => {
      this.switchInfo(params)
    })
  }

  switchInfo(params) {
    this.dataLoaded = false;
    if (Object.keys(params).length) {
      this.savingFromScratch = false;

      this.http.get<DigitalTwin>("/api/v1/digitaltwin/" + params['id'], this.httpOptions).subscribe(
        res => {
          this.form.patchValue(res);

          res.customerList.forEach(customer => {
            this.customerList.push(this.createCustomerFormGroupFromObject(res.production, res.isRegistered, customer));
          });

          if (res.isRegistered || res.production) {
            this.form.get('materialNumberBASF').disable();
            this.form.get('batchNumber').disable();
            this.form.get('digitalTwinType').disable();
          }

          if (res.digitalTwinType == DigitalTwinType.PartType) {
            this.form.get('batchNumber').disable();
          }

          this.setBreadcrumb(params['id']);

          this.dataLoaded = true;
        }
      );

      this.http.get<DigitalTwinAspectModelsDTO>("/api/v1/aspectmodel/" + params['id'], this.httpOptions).subscribe(
        res => {
          if (Object.keys(res).length > 0)
            this.digitalTwinAspectModelsDTO = res
        }
      );

    } else {
      this.setEnvironmentBpn();
      this.setBreadcrumb(params['id']);

      this.form.get('digitalTwinType').valueChanges.subscribe(value => {
        (value === DigitalTwinType.PartInstance) ? this.form.get('batchNumber').enable() : this.form.get('batchNumber').disable();
      });

      this.customerList.push(this.createCustomerFormGroup());
    }
  }

  setBreadcrumb(modifyPage: boolean) {
    if (this.production) {
      this.digitalTwinPageTitle = 'View Digital Twin';
      this.breadcrumb = [
        { title: 'Digital Twins', href: '/digital-twin' },
        { title: 'View Digital Twin', href: '' }
      ];
    } else {
      this.digitalTwinPageTitle = (modifyPage) ? 'Modify Digital Twin' : 'Create Digital Twin';

      this.breadcrumb = [
        { title: 'Digital Twins Test', href: '/digital-twin-test' },
        { title: (modifyPage) ? 'Modify Digital Twin Test' : 'Create Digital Twin Test', href: '' }
      ];
    }
  }

  createCustomerFormGroupFromObject(production: boolean, registered: boolean, customer: Customer): FormGroup {
    return this.formBuilder.group({

      customerName: [{ value: customer.customerName, disabled: production || registered }],
      customerMaterialNumber: [{
        value: customer.customerMaterialNumber,
        disabled: production || registered
      }, [Validators.required]],
      customerBpn: [{ value: customer.customerBpn, disabled: production || registered }, [Validators.required]],
      bilateralContract: [{
        value: customer.bilateralContract,
        disabled: production || registered
      }, [Validators.required]],
      isNewCustomer: [false]
    });
  }

  setEnvironmentBpn() {
    this.http.get<any>("/api/v1/digitaltwin/environmentbpn").subscribe(
      res => {
        this.form.get('bpn').setValue(res.bpn);
        this.dataLoaded = true;
      }
    );
  }

  createDigitalTwin() {
    if (this.form.valid) {
      const formData: DigitalTwin = this.form.value;

      this.http.post<DigitalTwin>("/api/v1/digitaltwin", formData, this.httpOptions).subscribe(
        (response) => {
          this.messageService.sendMessage('Digital Twin created successfully!');
          this.router.navigate(['/digital-twin-test']);
        },
        (error) => {
          if (error.status == 500 || error.status == 405) {
            this.activateAlert('red-snackbar', error.error.error);
          } else {
            this.activateAlert('red-snackbar', error.error);
          }
        }
      )
    } else {
      this.activateAlert('red-snackbar', this.warningInputFieldsRequired);
    }
  }

  modifyDigitalTwin() {
    if (this.form.valid) {
      this.dataLoaded = false;
      const formData: DigitalTwin = this.form.getRawValue();

      this.http.put<DigitalTwin>('/api/v1/digitaltwin', formData, this.httpOptions).subscribe(
        (response) => {
          this.activateAlert('green-snackbar', "Digital Twin modified successfully!");

          this.disableCustomerList();

          this.dataLoaded = true;
        },
        (error) => {
          if (error.status == 500 || error.status == 405) {
            this.activateAlert('red-snackbar', error.error.error);
          } else {
            this.activateAlert('red-snackbar', error.error);
          }
          this.dataLoaded = true;
        })
    } else {
      this.activateAlert('red-snackbar', this.warningInputFieldsRequired);
    }
  }

  disableCustomerList() {
    if (this.isRegistered) {
      this.customerList.controls.forEach((customerGroup: FormGroup) => {
        Object.keys(customerGroup.controls).forEach(key => {
          if (key === 'isNewCustomer') {
            customerGroup.get(key).setValue(false);
          } else {
            customerGroup.get(key).disable();
          }
        });
      });
    }
  }

  openConfirmationPopup() {
    if (this.isRegistered) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        data: {
          dialogTitle: 'Modify Digital Twin',
          dialogContent: `
          <p>Should you opt to implement modifications to a registered Digital Twin, the following changes are to be anticipated:</p>
          <ul>
            <li>The AAS registered within the DTR (Digital Twin Registry) will change.</li>
            <br>
            <li>A new Access Policy, Usage Policy and Contract Definition will be created for each of the new customers added.</li>
          </ul>`,
          buttonText: 'Modify'
        },
        position: { top: '200px' },
        maxWidth: '50vw',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result !== undefined) {
          this.modifyDigitalTwin();
        }
      });
    } else {
      this.modifyDigitalTwin();
    }
    return false;
  }

  get customerList(): FormArray {
    return this.form.get('customerList') as FormArray;
  }

  createCustomerFormGroup(): FormGroup {
    return this.formBuilder.group({
      bilateralContract: ['', [Validators.required]],
      customerName: [''],
      customerMaterialNumber: ['', [Validators.required]],
      customerBpn: ['', [Validators.required]],
      isNewCustomer: [true]
    });
  }

  getCustomerControl(index: number, controlName: string): FormControl {
    return this.customerList.at(index).get(controlName) as FormControl;
  }

  onClickAddCustomer() {
    let customerEmpty = false;

    this.customerList.controls.forEach((customerGroup: FormGroup) => {
      if (customerGroup.get('customerMaterialNumber').value === '' || customerGroup.get('customerBpn').value === '') {
        this.activateAlert('red-snackbar', 'Please fill out the new customer information before adding another new customer.');
        customerEmpty = true;
      }
    });

    if (!customerEmpty)
      this.customerList.push(this.createCustomerFormGroup());
  }

  onClickRemoveCustomer(index: number) {
    if (this.customerList.length > 1) {
      this.customerList.removeAt(index);
    } else {
      this.activateAlert('red-snackbar', 'A Digital Twin must have at least one customer.');
    }
  }

  get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  get isRegistered(): boolean {
    return this.form.get('isRegistered').value;
  }

  get production(): boolean {
    return this.form.get('production').value;
  }

  activateAlert(type: string, message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: [type]
    });
  }

}


