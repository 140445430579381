import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Customer } from 'src/app/shared/models/aspects/aspect-model.dto';
import { Batch } from 'src/app/shared/models/aspects/batch.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-batch-new',
  templateUrl: './batch-new.component.html',
  styleUrls: ['./batch-new.component.css']
})
export class BatchNewComponent {
  form: FormGroup;
  breadcrumb: any;

  @Input() idFromOutsideTheComponent: string

  accessPolicyJson: string
  contractPolicyJsonList: { name: string, json: any }[] = []

  textButton: string = 'Create Batch';

  constructor(
    private snackBar: MatSnackBar,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private messageService: MessageService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: undefined,
      cxIdentificationList: [],
      digitalTwinId: ['', [Validators.required]],
      isRegistered: false,
      materialClassification: ['', [Validators.required]],
      materialNameManufacturer: ['', [Validators.required]],
      customers: []
    });

    this.getInformation();
  }

  getInformation() {
    this.activatedRouter.params.subscribe(params => {
      this.switchInfo(params)
    })
  }

  switchInfo(params) {
    let paramBatchId = (params['idBatch']) ? params['idBatch'] : this.idFromOutsideTheComponent;

    if (paramBatchId) {
      this.http.get<Batch>("/api/v1/batch/" + paramBatchId, this.HttpOptions).subscribe(
        res => {
          this.form.patchValue(res);

          this.textButton = "Modify Batch";
          this.form.get('digitalTwinId').disable();

          if (this.idFromOutsideTheComponent) {
            this.form.get('materialClassification').disable();
            this.form.get('materialNameManufacturer').disable();
          }

          if (!this.idFromOutsideTheComponent) {
            this.getAccessPolicy();
            this.getContractPolicyList();
          }
        }
      );
    }

    this.setBreadcrumb(paramBatchId);

    if (params['digitalTwinId']) {
      this.form.get('digitalTwinId').disable();
      this.form.patchValue({
        digitalTwinId: params['digitalTwinId'],
      });
    }
  }

  getAccessPolicy() {
    // Get AccessPolicy
    if (this.customersList) {
      let firstElement = this.customersList[0].accessPolicyId;
      this.http.get<string>("/api/v1/edc/policy/" + firstElement).subscribe(
        res => {
          this.accessPolicyJson = JSON.stringify(res, null, 4)
        }
      );
    }
  }

  getContractPolicyList() {
    // Get ContractPolicy
    if (this.customersList) {
      this.customersList.forEach((customer: Customer) => {
        this.http.get<string>("/api/v1/edc/policy/" + customer.usagePolicyId).subscribe(
          res => {
            this.contractPolicyJsonList.push({
              name: "Usage Policy - " + customer.customerBpn,
              json: JSON.stringify(res, null, 4)
            });
          }
        );
      });
    }
  }

  setBreadcrumb(modifyPage: boolean) {
    let title: string = (modifyPage) ? 'Modify Batch' : 'Create Batch'

    this.breadcrumb = [
      { title: 'Batch Aspect Models', href: '/batch' },
      { title: title, href: '' }
    ];
  }

  createOrModifyBatch() {
    if (this.form.valid) {
      const formData: Batch = this.form.getRawValue();
      (this.id === null) ? this.createBatch(formData) : this.modifyBatch(formData);
    }
  }

  createBatch(formData) {
    this.http.post<Batch>("/api/v1/batch", formData, this.HttpOptions).subscribe(
      (response) => {
        this.messageService.sendMessage('Batch aspect model created successfully!');
        this.router.navigate(['/batch']);
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    )

  }

  modifyBatch(formData) {
    if (!this.idFromOutsideTheComponent) {
      const formData: Batch = this.form.getRawValue();

      this.http.put<Batch>('/api/v1/batch', formData, this.HttpOptions).subscribe((response) => {
        this.snackBar.open("Batch modified successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      },
        (error) => {
          if (error.status == 500 || error.status == 405) {
            this.snackBar.open(error.error.error, '', {
              duration: 5000,
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ['red-snackbar']
            });
          } else {
            this.snackBar.open(error.error, '', {
              duration: 5000,
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ['red-snackbar']
            });
          }
        })
    } else {
      this.router.navigate(['/batch/new', this.idFromOutsideTheComponent]);
    }
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  get cxIdentificationListToString(): string {
    return Array.from(this.cxIdentificationList).join(', ');
  }

  get cxIdentificationList(): string[] {
    return this.form.get('cxIdentificationList').value;
  }

  get customersList(): Customer[] {
    return this.form.get('customers').value;
  }

  get id(): string {
    return this.form.get('id').value;
  }

  get isRegistered(): boolean {
    return this.form.get('isRegistered').value;
  }
}
