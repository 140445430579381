import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Customer } from 'src/app/shared/models/aspects/aspect-model.dto';
import { PartTypeInformation } from 'src/app/shared/models/aspects/part-type-information.dto';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-part-type-information-new',
  templateUrl: './part-type-information-new.component.html',
  styleUrls: ['./part-type-information-new.component.css']
})
export class PartTypeInformationNewComponent {
  form: FormGroup;
  breadcrumb: any;

  @Input() idFromOutsideTheComponent: string

  accessPolicyJson: string
  contractPolicyJsonList: { name: string, json: any }[] = []

  textButton: string = 'Create PartTypeInformation';

  constructor(
    private snackBar: MatSnackBar,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private messageService: MessageService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: undefined,
      cxIdentificationList: [],
      digitalTwinId: ['', [Validators.required]],
      isRegistered: false,
      
      materialClassification: ['', [Validators.required]],
      materialNameManufacturer: ['', [Validators.required]],
      customers: []
    });

    this.getInformation();
  }

  getInformation() {
    this.activatedRouter.params.subscribe(params => {
      this.switchInfo(params)
    })
  }

  switchInfo(params) {
    let paramPartTypeInformationId = (params['idPart']) ? params['idPart'] : this.idFromOutsideTheComponent;

    if (paramPartTypeInformationId) {

      this.http.get<PartTypeInformation>("/api/v1/parttypeinformation/" + paramPartTypeInformationId, this.HttpOptions).subscribe(
        res => {
          this.form.patchValue(res);

          this.textButton = "Modify PartTypeInformation";
          this.form.get('digitalTwinId').disable();

          if (this.idFromOutsideTheComponent) {
            this.form.get('materialClassification').disable();
            this.form.get('materialNameManufacturer').disable();
          }

          if (!this.idFromOutsideTheComponent) {
            this.getAccessPolicy();
            this.getContractPolicyList();
          }
        }
      );

    }

    this.setBreadcrumb(paramPartTypeInformationId);

    if (params['digitalTwinId']) {
      this.form.get('digitalTwinId').disable();
      this.form.patchValue({
        digitalTwinId: params['digitalTwinId'],
      });
    }
  }

  getAccessPolicy() {
    // Get AccessPolicy
    if (this.customerList) {
      let firstElement = this.customerList[0].accessPolicyId;
      this.http.get<string>("/api/v1/edc/policy/" + firstElement).subscribe(
        res => {
          this.accessPolicyJson = JSON.stringify(res, null, 4)
        }
      );
    }
  }

  getContractPolicyList() {
    // Get ContractPolicy
    if (this.customerList) {
      this.customerList.forEach((customer: Customer) => {
        this.http.get<string>("/api/v1/edc/policy/" + customer.usagePolicyId).subscribe(
          res => {
            this.contractPolicyJsonList.push({
              name: "Usage Policy - " + customer.customerBpn,
              json: JSON.stringify(res, null, 4)
            });
          }
        );
      });
    }
  }

  setBreadcrumb(modifyPage: boolean) {
    let title: string = (modifyPage) ? 'Modify PartTypeInformation' : 'Create PartTypeInformation'

    this.breadcrumb = [
      { title: 'PartTypeInformation Aspect Models', href: '/part-type-information' },
      { title: title, href: '' }
    ];
  }

  createOrModifyPartTypeInformation() {
    if (this.form.valid) {
      const formData: PartTypeInformation = this.form.getRawValue();
      (this.id === null) ? this.createPartTypeInformation(formData) : this.modifyPartTypeInformation(formData);
    }
  }

  createPartTypeInformation(formData: PartTypeInformation) {
    this.http.post<PartTypeInformation>("/api/v1/parttypeinformation", formData, this.HttpOptions).subscribe(
      (response) => {
        this.messageService.sendMessage('PartTypeInformation aspect model created successfully!');
        this.router.navigate(['/part-type-information']);
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    )
  }

  modifyPartTypeInformation(formData: PartTypeInformation) {
    if (!this.idFromOutsideTheComponent) {
      this.http.put<PartTypeInformation>('/api/v1/parttypeinformation', formData, this.HttpOptions).subscribe(data => {
        this.snackBar.open("PartTypeInformation modified successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      })
    } else {
      this.router.navigate(['/part-type-information/new', this.idFromOutsideTheComponent]);
    }
  }

  get HttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  get cxIdentificationListToString(): string {
    return Array.from(this.cxIdentificationList).join(', ');
  }

  get cxIdentificationList(): string[] {
    return this.form.get('cxIdentificationList').value;
  }

  get customerList(): Customer[] {
    return this.form.get('customers').value;
  }

  get id(): string {
    return this.form.get('id').value;
  }

  get isRegistered(): boolean {
    return this.form.get('isRegistered').value;
  }

}
