import { Component } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDeletePopupComponent } from '../../shared/popup/confirm-delete-popup/confirm-delete-popup.component';
import { PartTypeInformation } from '../../shared/models/aspects/part-type-information.dto';
import { RegistryAspectModel } from 'src/app/shared/models/aspects/registry-aspect-model.dto';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageService } from '../../shared/services/message.service';
import { HelpersService } from "../../shared/services/helpers.service";

@Component({
  selector: 'app-part-type-information',
  templateUrl: './part-type-information.component.html',
  styleUrls: ['./part-type-information.component.css']
})
export class PartTypeInformationComponent {
  dataSource = new MatTableDataSource<PartTypeInformation>();
  dataLoaded = false;
  displayedColumns: string[] = [
    'digitalTwinId',
    'materialClassification',
    'materialNameManufacturer',
    'isRegistered',
    'options'
  ];

  alertShow: boolean = false
  colorType: string
  alertMessage: string

  constructor(
    private snackBar: MatSnackBar,
    private messageService: MessageService,
    private http: HttpClient,
    public dialog: MatDialog) {
    this.getInformation();
  }

  getInformation() {
    this.http.get<any>("/api/v1/parttypeinformation").pipe(map(res => res.map((x: PartTypeInformation) => ({
      id: x.id,
      cxIdentificationList: x.cxIdentificationList,
      digitalTwinId: x.digitalTwinId,
      isRegistered: x.isRegistered,
      materialClassification: x.materialClassification,
      materialNameManufacturer: x.materialNameManufacturer
    })))).subscribe(
      res => {
        this.dataSource.data = res;
        this.dataLoaded = true;
      }
    );

    this.messageService.getMessage().subscribe(message => {
      if (message) {
        this.snackBar.open(message.text, '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });
      }
    });
  }

  getComponentHistory(id: string) {
    HelpersService.getComponentHistory(id, false, this.http, this.dialog);
  }

  registerPartTypeInformation(aspectModelId: string) {
    this.dataLoaded = false;

    const registryAspectModel: RegistryAspectModel = {
      aspectModelId: aspectModelId,
      aspectModelType: 'partTypeInformation'
    }

    this.http.post<PartTypeInformation>('/api/v1/registry/aspectmodel', registryAspectModel).subscribe(
      (response) => {
        this.snackBar.open("PartTypeInformation registered successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });

        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;

        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    )
  }

  unregisterPartTypeInformation(aspectModelId: string) {
    this.dataLoaded = false;

    const registryAspectModel: RegistryAspectModel = {
      aspectModelId: aspectModelId,
      aspectModelType: 'partTypeInformation'
    }

    this.http.delete('/api/v1/registry/aspectmodel', { body: registryAspectModel }).subscribe(
      (response) => {
        this.snackBar.open("PartTypeInformation unregistered successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });

        this.getInformation()
      },
      (error) => {
        this.dataLoaded = true;

        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      }
    )
  }

  deletePartTypeInformation(aspectModelId: string) {
    this.http.delete(`/api/v1/parttypeinformation/${aspectModelId}`).subscribe(
      (response) => {
        this.snackBar.open("PartTypeInformation deleted successfully!", '', {
          duration: 5000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
          panelClass: ['green-snackbar']
        });

        this.getInformation()
      },
      (error) => {
        if (error.status == 500 || error.status == 405) {
          this.snackBar.open(error.error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open(error.error, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
        }
      })
  }

  openConfirmationPopup(aspectModelId: string) {
    const dialogRef = this.dialog.open(ConfirmDeletePopupComponent, {
      data: { elementToDelete: 'PartTypeInformation', id: aspectModelId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        this.deletePartTypeInformation(aspectModelId);
    });
  }

}
