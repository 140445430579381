import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from '@angular/router';

interface Method {
  value: string;
  enabled: boolean;
}

@Component({
  selector: 'app-edc-transfer-data',
  templateUrl: './edc-transfer-data.component.html',
  styleUrls: ['./edc-transfer-data.component.css']
})
export class EdcTransferDataComponent implements OnInit {
  form: FormGroup;
  showBodyField: boolean = false;
  assetId: string;
  resultLoaded: boolean = false;

  methods: Method[] = [
    {value: 'GET', enabled: true},
    {value: 'POST', enabled: true},
    {value: 'PUT', enabled: false},
    {value: 'DELETE', enabled: false},
  ]

  assetDataResult: string = '{ Empty }'

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const transferKey = params['transferKey'];

      try {
        if (transferKey) {
          const transferData = JSON.parse(sessionStorage.getItem(transferKey));
          // Clean data after usage
          sessionStorage.removeItem(transferKey);

          if (transferData) {
            this.form = this.formBuilder.group({
              method: ['GET', [Validators.required]],
              assetId: [transferData.assetId, [Validators.required]],
              endpoint: [transferData.endpoint, [Validators.required]],
              token: [transferData.token, []],
              body: ['']
            });
          }
        }

        // If there is no transferKey then we create an empty form
        if (!this.form) {
          this.form = this.formBuilder.group({
            method: ['GET', [Validators.required]],
            assetId: ['', [Validators.required]],
            endpoint: ['', [Validators.required]],
            token: ['', []],
            body: ['']
          });
        }

        // Subscribe to changes afther the form has been initialized
        this.form.get('method').valueChanges.subscribe(value => {
          this.showBodyField = (value === 'POST');
          const bodyControl = this.form.get('body');

          if (this.showBodyField) {
            bodyControl.setValidators([Validators.required]);
          } else {
            bodyControl.setValidators(null);
            bodyControl.reset();
          }

          bodyControl.updateValueAndValidity();
          this.cdr.detectChanges();
        });

      } catch (error) {
        console.error('Error initializing form:', error);
        // Create an empty form if error
        this.form = this.formBuilder.group({
          method: ['GET', [Validators.required]],
          assetId: ['', [Validators.required]],
          endpoint: ['', [Validators.required]],
          token: [''],
          body: ['']
        });
      }
    });

    this.resultLoaded = true;

    console.log(this.resultLoaded)
  }

  getAssetData() {
    this.resultLoaded = false;
    this.cdr.detectChanges();

    console.log(this.resultLoaded)

    if (this.form.valid) {
      const assetConsumptionRequestDTO = {
        method: this.form.value.method,
        endpoint: this.form.value.endpoint,
        token: this.form.value.token,
        body: this.form.value.body || null
      };

      if (assetConsumptionRequestDTO.method === 'POST' && assetConsumptionRequestDTO.body) {
        try {
          if (typeof assetConsumptionRequestDTO.body === 'string') {
            assetConsumptionRequestDTO.body = JSON.parse(assetConsumptionRequestDTO.body);
          }
        } catch (e) {
          this.snackBar.open('Invalid JSON format', '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
          return;
        }
      } else {
        delete assetConsumptionRequestDTO.body;
      }

      this.http.post<any>(`/api/v1/edc/transfer-data`, assetConsumptionRequestDTO, this.httpOptions).subscribe(
        (response) => {
          this.assetDataResult = JSON.stringify(response, null, 2);
          this.resultLoaded = true;
        },
        (error) => {
          this.snackBar.open(`Error: ${error.message}`, '', {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['red-snackbar']
          });
          this.resultLoaded = true;
        }
      );
    }
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.assetDataResult).then(
      () => this.snackBar.open('Copied to clipboard', '', {
        duration: 2000,
        verticalPosition: 'top',
      }),
      (err) => console.error('Could not copy text: ', err)
    );
  }

  downloadResponse() {
    // Convert string to Blob
    const jsonContent = new Blob([this.assetDataResult], {type: 'application/json'});

    // Create a temporary URL pointing to the Blob
    const url = window.URL.createObjectURL(jsonContent);

    // Create a temporary link element, set filename and trigger click
    const link = document.createElement('a');
    link.href = url;
    link.download = 'asset-payload.json';
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  }

  get httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  get enabledMethods(): Method[] {
    return this.methods.filter(method => method.enabled);
  }

}
